import * as React from "react"
import {
  Box,
  Button,
  Flex,
  Heading,
  Link,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
} from "@chakra-ui/react"
import { graphql } from "gatsby"
import { Icon } from "@chakra-ui/icons"
import { FaGithub, FaGitlab, FaPython } from "react-icons/fa"
import { RiScales3Fill } from "react-icons/ri"
import { BiCodeCurly } from "react-icons/bi"
import { SiTypescript } from "react-icons/si"
import { DiGo } from "react-icons/di"
import HomeLayout from "../components/HomeLayout"

interface ProjectData {
  blurb: string
  description: string[]
  languages: string[]
  license: string
  name: string
  repo: string
}

interface Node {
  project: ProjectData
}

interface Nodes {
  nodes: Node[]
}

interface GraphResponse {
  data: QueryData
}

interface QueryData {
  allProjectsJson: Nodes
}

const ProjectsPage = ({ data }: GraphResponse): JSX.Element => {
  const { allProjectsJson }: QueryData = data
  return (
    <HomeLayout pageTitle={"projects"}>
      {allProjectsJson.nodes.map((project: any, idx: number) => {
        return <Project project={project} key={idx} />
      })}
    </HomeLayout>
  )
}

const Project = (node: Node): JSX.Element => {
  const description = node.project.description.join(" ")
  let icon = node.project.repo.includes("gitlab") ? (
    <Icon as={FaGitlab} />
  ) : (
    <Icon as={FaGithub} />
  )
  return (
    <Box py={6} as={"section"} id={"project-component"}>
      <Flex
        justifyContent={"space-between"}
        alignItems={"center"}
        flexWrap={"wrap"}
      >
        <Box>
          <Heading py={2}>{node.project.name}</Heading>
        </Box>
        <Flex>
          {node.project.languages.map((lang: string, idx: number) => {
            let icon, bgcolor, color
            switch (lang) {
              case "python":
                icon = <TagLeftIcon as={FaPython} />
                color = "#ffdb65"
                bgcolor = "#3671a1"
                break
              case "go":
                icon = <TagLeftIcon as={DiGo} />
                bgcolor = "#79d4fd"
                break
              case "typescript":
                icon = <TagLeftIcon as={SiTypescript} />
                color = "#3178c6"
                bgcolor = "gray.100"
                break
              default:
                icon = <TagLeftIcon as={BiCodeCurly} />
                bgcolor = "gray.200"
                color = "black"
                break
            }
            return (
              <span key={idx}>
                <Tag ml={1} backgroundColor={bgcolor} color={color}>
                  {icon}
                  <TagLabel>{lang}</TagLabel>
                </Tag>{" "}
              </span>
            )
          })}
          <Tag ml={1}>
            <TagLeftIcon as={RiScales3Fill} />
            <TagLabel>{node.project.license}</TagLabel>
          </Tag>
        </Flex>
      </Flex>
      <Box my={4}>
        <Text dangerouslySetInnerHTML={{ __html: description }} />
      </Box>
      <Link _hover={undefined} href={node.project.repo}>
        <Button leftIcon={icon} size={"xs"} colorScheme={"blue"} my={2}>
          Project Home
        </Button>
      </Link>
    </Box>
  )
}

export default ProjectsPage

export const query = graphql`
  query projects {
    allProjectsJson {
      nodes {
        blurb
        description
        languages
        license
        name
        repo
      }
    }
  }
`
